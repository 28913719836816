// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  mode: 'default',
  remoteConfig: {
    uriGetPolicyList:
      'https://apis-dev.panindai-ichilife.co.id/goapps/alteration/policies/alteration/{0}/agents',
    uriGetPolicy:
      'https://apis-dev.panindai-ichilife.co.id/goapps/alteration/policies/{0}',
    uriGetTransactionHistory:
      'https://apis-dev.panindai-ichilife.co.id/goapps/alteration/transactions/histories?agentNum={0}',
    uriGetPremiMinMax:
      'https://apis-dev.panindai-ichilife.co.id/goapps/alteration/products/premis/min-max?product={0}&currency={1}',
    uriPostCalculateUnapplied:
      'https://apis-dev.panindai-ichilife.co.id/goapps/alteration/premis/calculate',
    uriPostValidateAddRider:
      'https://apis-dev.panindai-ichilife.co.id/goapps/alteration/products/riders/validate',
    uriPostCalculateCoi:
      'https://apis-dev.panindai-ichilife.co.id/goapps/alteration/cois/calculate',
    uriPostCalculateInvestment:
      'https://apis-dev.panindai-ichilife.co.id/goapps/alteration/investments/calculate',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
